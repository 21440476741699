import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import Main from "../components/main"
import CallToAction from "../components/callToAction"

const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Imprendi responsabilmente" />
    <Header />
    <Hero />
    <Main />
    <CallToAction />
  </Layout>
)}

export default IndexPage