import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col, Visible } from "react-grid-system"
import illustration from "../images/illu-baloon.jpg"
import { DisplaySmall, Body } from "./typography"
import SimpleForm from "./simpleForm"

const CallToAction = () => (
  <Wrapper>
    <Container>
      <Row>
        <Contact>
          <Details xs={12} sm={12}>
            <Body size={"21px"}>
              I clienti e consumatori chiedono sempre più un atteggiamento
              responsabile da parte delle aziende.
            </Body>
            <DisplaySmall uppercase weight={700}>
              Non rimanere indietro,
              <br />
              guarda avanti.
            </DisplaySmall>
          </Details>
          <SimpleForm />
        </Contact>
        <Image>
          <Visible md lg xl xxl>
            <img src={illustration} />
          </Visible>
        </Image>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
    padding-top: 3rem;
    padding-bottom: 3rem;
`

const Details = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Image = styled(Col)`
  text-align: center;
`

const Contact = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default CallToAction
