import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import guideCover from "../images/guida-copertina.png"
import { Container, Row, Col } from "react-grid-system"
import { Heading, HeadingSmall, Lead, Body } from "./typography"
import { BtnLink } from "./button"

const Main = () => (
  <Wrapper>
    <CustomContainer>
      <Row>
        <Col>
          <Intro>
            <Heading align={"center"}>Il metodo multi-sostenibile</Heading>
            <Lead align={"center"} weight={400}>
              Grazie alle sue competenze diversificate in consulenza Marketing,
              Vendite e Comunicazione, MULTI garantisce un approccio strategico
              alla CSR coerente all’azienda e al suo contesto di riferimento,
              sviluppando un percorso in due fasi.
            </Lead>
          </Intro>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <Row>
              <Col sm={6}>
                <BigNumber>01</BigNumber>
              </Col>
              <Col sm={6}>
                <Details>
                  <HeadingSmall>Analisi preliminare</HeadingSmall>
                  <ul>
                    <li>
                      <strong>Questionario strutturato</strong> che permette di
                      valutare la consapevolezza dell’azienda sui temi di CSR
                    </li>
                    <li>
                      <strong>Analisi</strong> del contesto di mercato, dei
                      competitor, dell’interesse dei clienti
                    </li>
                    <li>
                      <strong>Piano di azione</strong> che comprende formazione,
                      affiancamento e sviluppo di attività
                    </li>
                  </ul>
                </Details>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <Row>
              <Col sm={6}>
                <BigNumber>02</BigNumber>
              </Col>
              <Col sm={6}>
                <Details>
                  <HeadingSmall>Piano d'azione</HeadingSmall>
                  <Body>
                    <strong>
                      Individuazione degli stakeholders e delle attività in
                      linea con la propria realtà aziendale consente di creare
                      output coerenti con la CSR dell’azienda
                    </strong>{" "}
                    (analisi di materialità, progetti ESG, attività di
                    comunicazione CSR, report di sostenibilità e molti altri).
                  </Body>
                </Details>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <BoxWhite>
            <Row>
              <Col sm={6}>
                <img
                  src={guideCover}
                  alt="Guida alla corporate social responsibility"
                />
              </Col>
              <Col sm={6}>
                <Details>
                  <Lead
                    uppercase
                    color={DesignTokens.colors.primary[500]}
                    size={"1.5rem"}
                  >
                    Guida alla corporate social responsibility
                    <br />
                    La proposta MULTI
                  </Lead>
                  <Body weight={700}>
                    Dentro troverai una panoramica dei vantaggi per le imprese,
                    la spiegazione del metodo MULTI in 8 step divisi in due fasi
                    operative e il dettaglio degli output che MULTI può fornire.
                  </Body>
                  <BtnLink to="/guida/">Scarica la guida</BtnLink>
                </Details>
              </Col>
            </Row>
          </BoxWhite>
        </Col>
      </Row>
    </CustomContainer>
  </Wrapper>
)

const Intro = styled.section`
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Details = styled.div`
  & > * {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Wrapper = styled.section`
  background: ${DesignTokens.colors.primary[500]};
  color: ${DesignTokens.colors.white};
  padding: 6rem 0;
  @media screen and (min-width: 768px) {
    padding: 6rem 3rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 6rem 5rem;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: 6rem 10rem;
  }
`

const BigNumber = styled.div`
  font-size: 200px;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 5px ${DesignTokens.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1;
`

const CustomContainer = styled(Container)`
  max-width: 1024px !important;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Box = styled.div`
  padding: 3rem 2rem;
  border: 1px solid ${DesignTokens.colors.tertiary[500]};
`

const BoxWhite = styled.div`
  padding: 3rem 2rem;
  color: ${DesignTokens.colors.black};
  background: ${DesignTokens.colors.tertiary[500]};
`

export default Main
