import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { BtnLink } from "./button"

const SimpleForm = () => {
  const [email, setEmail] = useState("")
  return (
    <Form>
      <form
        onSubmit={event => {
          event.preventDefault()
          navigate("/contatti", {
            state: { email: email },
          })
        }}
      >
        <section>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            placeholder="inserisci la tua email"
            onChange={e => setEmail(e.target.value)}
            required
          />
          <BtnLink as="input" type="submit" value="CONTATTACI" />
        </section>
      </form>
    </Form>
  )
}

export default SimpleForm

const Form = styled.div`
    width: 100%;
  form {
    label {
      margin-bottom: ${DesignTokens.spacing[2]};
      text-transform: uppercase;
    }
    input[type="email"],
    textarea {
      padding: ${DesignTokens.spacing[5]};
      border: 3px solid ${DesignTokens.colors.primary[500]};
      color: ${DesignTokens.colors.primary[500]};
      background: transparent;
      font-weight: bold;
      flex: 2;
      margin-right: 1rem;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[7]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
