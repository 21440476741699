import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import heroImage from "../images/hero-image.jpg"
import { Container, Row, Col, Visible } from "react-grid-system"
import { BtnLink } from "./button"
import { Display, DisplaySmall, Body } from "./typography"

const Hero = () => (
  <Wrapper>
    <CustomContainer background={heroImage}>
      <Row>
        <Content xs={12} md={12} lg={10} xl={9} xxl={8}>
          <Display>
            Imprendi
            <br />
            <span>responsabilmente</span>
          </Display>
          <DisplaySmall>
            Il tuo percorso di
            <br />
            Corporate Social Responsibility
            <br />
            con MULTI
          </DisplaySmall>
          <hr />
          <div>
            <Body weight={700}>
              Tutte le risposte alle domande
              <br />
              che non sapevi di avere sulla CSR.
            </Body>
            <Body weight={700} color={DesignTokens.colors.primary[500]}>
              Scopri quanto ne sai.
            </Body>
          </div>
          <BtnLink to="/questionario/">Inizia il test</BtnLink>
        </Content>
      </Row>
    </CustomContainer>
  </Wrapper>
)

const Content = styled(Col)`
  padding: 3rem 0;
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }

  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomContainer = styled(Container)`
  margin-top: 3rem;
  margin-bottom: 3rem;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    @media screen and (max-width: 1023px) {
      background-position: center;
      opacity: 0.2;
    }
  }
`

const Image = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Wrapper = styled.section`
  hr {
    width: 64px;
    height: 3px;
    background: ${DesignTokens.colors.black};
  }
`

export default Hero
